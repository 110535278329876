<template>
  <base-section
    id="pricing-plan"
    class="text-center"
  >
    <base-section-heading title="Preise" />

    <v-container>
      <v-row
        align="center"
        class="ma-0 fill-height text-center"
        justify="center"
      >
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="4"
        >
          <price-card v-bind="plan" />
        </v-col>
      </v-row>
    </v-container>
    Bitte beachten Sie dass im Verhinderungsfall mindestens 48h vorher abgesagt werden muss, ansonsten muss die Sitzung (1.5h) verrechnet werden.
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPricingPlan',

    components: {
      PriceCard: () => import('@/components/PriceCard'),
    },

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      internalPlans: [
        {
          title: 'Astrologische Beratung',
          subtitle: 'inkl Vorbereitung',
          monthly: 140,
          value: true,
          features: [
            'auf Wunsch inkl Aufnahme',
            'vor Ort, telefonisch oder per Zoom',
          ],
        },
        {
          title: 'Hypnosetherapie',
          subtitle: '-',
          monthly: 140,
          value: true,
          features: [
            'Die Hypnosetherapie findet in meiner Praxis statt.',
            'Steinacker 6, 9548 Matzingen',
          ],
        },
      ],
      interval: 0,
    }),

    computed: {
      plans () {
        return this.internalPlans.map(plan => {
          return {
            ...plan,
            features: plan.features,
            interval: 'Stunde',
            title: plan.title,
            subtitle: plan.subtitle,
            price: !this.interval
              ? plan.monthly
              : plan.yearly,
          }
        })
      },
    },
  }
</script>
